import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Col, Container, Row } from "react-bootstrap";

import image from "../../images/meeting.jpg";
import stretchinSvg from "../../images/stretching.svg";
import chooseSvg from "../../images/choose.svg";
import Layout from "../../components/layout";
import Section from "../../components/section";
import Typography from "../../components/typography";
import ScheduleForm from "../../components/scheduleForm";
import { StaticImage } from "gatsby-plugin-image";

const SbaLoan = () => {
  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section size="md" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Container className="text-center h-100">
            <div>
              <Typography type="head">SBA Loan</Typography>
            </div>
          </Container>
        </Parallax>
      </Section>
      <Section size="sm" color="dark" bg="light">
        <Container className=" py-5">
          <Typography className="mb-3" type="title">
            What is a SBA Loan?
          </Typography>
          <Typography type="paragraph">
            The Small Business Administration (SBA) is an autonomous U.S.
            government agency that provides assistance to small businesses. An
            SBA loan is a government loan to support and finance small
            businesses. There are different sizes of SBA loans ($25,000 or less,
            $25,001 to $50,000, More than $50,000), and different types of SBA
            loans: 7(a) loan program (small business), CDC/504 loan program
            (real estate/investments), microloan programs, and Disaster loan
            program
          </Typography>
        </Container>
      </Section>
      <Section color="light" bg="dark">
        <Container className="text-center py-5">
          <Typography type="title">Loan Details</Typography>
          <Row className="d-flex justify-content-center mx-auto">
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Max. Loan Amount</Typography>
              <Typography type="subject">$25K to $50,000+.</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Loan Term</Typography>
              <Typography type="subject">10-25 years</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Interest Rates</Typography>
              <Typography type="subject">5.5% - 8%</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Speed of Funding</Typography>
              <Typography type="subject">2-3 months</Typography>
            </Card>
          </Row>
          <Typography className="mt-5" type="title">
            Loan Requirements
          </Typography>
          <Row className="d-flex justify-content-center mx-auto">
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Annual Revenue</Typography>
              <Typography type="subject">$100,000+</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Credit Score</Typography>
              <Typography type="subject">620+</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Time in Business</Typography>
              <Typography type="subject">2+ years</Typography>
            </Card>
          </Row>
        </Container>
      </Section>
      <Section size="md">
        <Container
          fluid
          className="d-flex flex-nowrap h-100 align-items-center h-100 p-5"
        >
          <div className="nom d-inline-block me-5">
            <img
              className="h-100"
              style={{ maxHeight: "275px" }}
              src={stretchinSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
          <div className="d-inline-block flex-full">
            <Row className="w-100 h-100">
              <div className="flex-full">
                <Typography type="title">Pros</Typography>
                <ul>
                  <li>
                    Broad eligibility requirements→ Easily accessible to new
                    business owners
                  </li>
                  <li>Capped interest rates</li>
                  <li>Small and large amounts offered</li>
                  <li>
                    Information available to provide assistance and facilitate
                    your decision
                  </li>
                </ul>
              </div>
              <div className="flex-full mr-5">
                <Typography type="title">Cons</Typography>
                <ul>
                  <li>
                    Usually must make a down payment in order to receive funding
                  </li>
                  <li>Collateral could be required</li>
                  <li>Personal liability if the business defaults</li>
                  <li>Low credit applicants are generally not approved</li>
                </ul>
              </div>
            </Row>
          </div>
        </Container>
      </Section>
      <Section size="lg" bg="dark" color="light">
        <Container className="py-5">
          <Typography type="title">How To Apply for a SBA Loan</Typography>
          <Typography type="paragraph">
            With our simple online application, the process to receive a SBA
            loan is quick and easy.
          </Typography>
          <Row style={{ marginTop: "10%" }}>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">1</span>
              </Typography>
              <div>
                <Typography type="subject">Prequalify Online</Typography>
                <Typography type="paragraph">
                  Follow steps and enter basic information.
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">2</span>
              </Typography>
              <div>
                <Typography type="subject">Online Approval</Typography>
                <Typography type="paragraph">
                  After online approval, a consultant will reach out to discuss
                  loan options.
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">3</span>
              </Typography>
              <div>
                <Typography type="subject">Recieve Secure Funding</Typography>
                <Typography type="paragraph">
                  Funds will be directly deposited into your bank account and
                  available for immediate use.
                </Typography>
              </div>
            </div>
          </Row>

          <div style={{ marginTop: "10%" }}>
            <Typography type="title">Needed Documentation</Typography>
            <ul className="d-flex flex-wrap">
              <li className="m-0 me-5 mb-3">Business plan</li>
              <li className="m-0 me-5 mb-3">
                Driver's license/government-issued photo ID
              </li>
              <li className="m-0 me-5 mb-3">Recent bank statements</li>
              <li className="m-0 me-5 mb-3">Other financial documents</li>
            </ul>
          </div>
        </Container>
      </Section>
      <Section size="md">
        <Container className="d-flex flex-nowrap h-100 align-items-center justify-content-center h-100 py-5">
          <div className="nom d-inline-block me-5">
            <img
              className="h-100"
              style={{ maxHeight: "400px" }}
              src={chooseSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
          <div className="d-inline-block">
            <Typography type="title">Best Use of a SBA Loan</Typography>
            <div className="d-flex mx-auto">
              <ul className="flex-full">
                <li>Business expansion</li>
                <li>Long-term growth initiatives</li>
                <li>New equipment</li>
                <li>New products</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default SbaLoan;
